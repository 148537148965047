export default class SessionService {
  static setCookies(data) {
    return localStorage.setItem("cookies", JSON.stringify(data))
  }

  static getCookies() {
    return JSON.parse(localStorage.getItem("cookies") || "null")
  }

  static clearSessionStorage() {
    localStorage.clear()
    sessionStorage.clear()
  }
}
