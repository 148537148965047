import React from "react"

import { useInView } from "react-intersection-observer"

import { DecorColumn, DecorWrapper } from "@/components/Decor/styled"

const Decor = () => {
  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const animationVariants = {
    hidden: {
      y: "-100%",
    },
    visible: {
      y: 0,
      transition: {
        duration: 2,
        delay: 1,
        ease: "easeInOut",
      },
    },
  }

  return (
    <DecorWrapper
      ref={ref}
      initial="hidden"
      animate="visible"
      variants={animationVariants}
    >
      <DecorColumn number={1} />
      <DecorColumn number={2} />
      <DecorColumn number={3} />
      <DecorColumn number={4} />
      <DecorColumn number={5} />
      <DecorColumn number={6} />
    </DecorWrapper>
  )
}

export default Decor
