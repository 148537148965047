import * as React from "react"

import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import Decor from "@/components/Decor"
import { GlobalStyle } from "@/components/Layout/GlobalStyle"
import Cookies from "@/sections/Cookies"
import { theme } from "@/utils/theme"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <main>{children}</main>
      <Decor />
      <Cookies />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
