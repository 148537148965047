import { motion } from "framer-motion"
import styled from "styled-components"

import { basicGrid } from "@/components/Layout/styled"

export const DecorWrapper = styled(motion.div)`
  ${basicGrid};
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
`

export const DecorColumn = styled.div`
  position: relative;
  border-left: 1px solid ${({ theme }) => theme.colors.verticalDecor};
  border-right: 1px solid ${({ theme }) => theme.colors.verticalDecor};
  grid-column-start: ${({ number }) => number || 1};
  grid-column-end: span 1;
  width: 100%;
  height: 100vh;

  ${({ number }) => number === 5 && "display: none"};
  ${({ number }) => number === 6 && "display: none"};

  ${({ theme }) => theme.media.tablet} {
    ${({ number }) => number === 5 && "display: block"};
    ${({ number }) => number === 6 && "display: block"};
  }
`
