import { Link } from "gatsby"
import styled from "styled-components"

export const CookiesWrapper = styled.div`
  //width: 220px;
  height: 36px;
  position: fixed;
  z-index: 999;
  box-sizing: border-box;
  font-family: "AkzidGro", sans-serif;
  color: ${({ theme }) => theme.colors.darkGray};

  right: ${({ theme }) => theme.mainMargin.mobile};
  bottom: ${({ theme }) => theme.mainMargin.mobile};

  background: ${({ theme }) => theme.colors.background};
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.colors.darkGray};

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;

  ${({ theme }) => theme.media.tablet} {
    right: ${({ theme }) => theme.mainMargin.tablet};
    bottom: ${({ theme }) => theme.mainMargin.tablet};
  }

  ${({ theme }) => theme.media.desktop} {
    right: ${({ theme }) => theme.mainMargin.desktop};
    bottom: ${({ theme }) => theme.mainMargin.desktop};
  }
`

export const CookiesText = styled.p`
  font-size: 12px;
  max-width: 80vw;

  ${({ theme }) => theme.media.tablet} {
    font-size: 16px;
  }
`

export const StyledCookieLink = styled(Link)`
  font-family: "AkzidGro", sans-serif;
  color: ${({ theme }) => theme.colors.darkGray};
  text-decoration: underline;
`

export const CookiesAction = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.background};
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "AkzidGro", sans-serif;
  color: ${({ theme }) => theme.colors.darkGray};

  ${({ theme }) => theme.media.tablet} {
    font-size: 16px;
  }
`
