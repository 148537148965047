import { colors } from "./colors"

export const theme = {
  colors,
  font: {
    size: {
      s: "1.2rem",
      m: "1.6rem",
      l: "2.0rem",
      xl: "2.4rem",
    },
  },
  mainMargin: {
    mobile: "8px",
    tablet: "23px",
    desktop: "32px",
    desktopLarge: "32px",
  },
  media: {
    mobile: `@media (min-width: 375px)`,
    tablet: `@media (min-width: 1024px)`,
    desktop: `@media (min-width: 1440px)`,
    desktopLarge: `@media (min-width: 1920px)`,
  },
}
