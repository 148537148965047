import styled, { css } from "styled-components"

export const basicGrid = css`
  display: grid;
  column-gap: 8px;
  grid-template-columns: repeat(4, 1fr);

  width: calc(100vw - 2 * ${({ theme }) => theme.mainMargin.mobile});
  margin: 0 ${({ theme }) => theme.mainMargin.mobile};

  ${({ theme }) => theme.media.tablet} {
    column-gap: 12px;
    grid-template-columns: repeat(6, 1fr);
    width: calc(100vw - 2 * ${({ theme }) => theme.mainMargin.tablet});
    margin: 0 ${({ theme }) => theme.mainMargin.tablet};
  }

  ${({ theme }) => theme.media.desktop} {
    column-gap: 16px;
    margin: 0 ${({ theme }) => theme.mainMargin.desktop};
    width: calc(100vw - 2 * ${({ theme }) => theme.mainMargin.desktop});
  }
`

export const Container = styled.div`
  ${basicGrid};
`
