import React, { useEffect, useState } from "react"

import {
  CookiesAction,
  CookiesText,
  CookiesWrapper,
  StyledCookieLink,
} from "@/sections/Cookies/styled"
import SessionService from "@/services/session-services"

const Cookies = () => {
  const [showCookies, setShowCookies] = useState(true)
  useEffect(() => {
    if (SessionService.getCookies()) {
      setShowCookies(false)
    }
  }, [])

  const handleCookiesAccept = () => {
    SessionService.setCookies(new Date())
    setShowCookies(false)
  }

  if (!showCookies) return null
  return (
    <CookiesWrapper>
      <CookiesText>
        This sites uses cookies. By continuing to browse the site you are
        agreeing to{" "}
        <StyledCookieLink to="/privacy-policy">
          our privacy policy.
        </StyledCookieLink>
      </CookiesText>
      <CookiesAction onClick={() => handleCookiesAccept()}>OK</CookiesAction>
    </CookiesWrapper>
  )
}

export default Cookies
